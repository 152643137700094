import axios from '@/axios'
import {getLoaActionType, getLoaMutationsType} from "../adminLoa";

const state = {
    items: null,
    userId: null,
    errors: null
}

export const getRegisterMutationsType = {
    getRegisterStart: '[register]getRegisterStart',
    getRegisterSuccess: '[register]getRegisterSuccess',
    getRegisterError: '[register]getRegisterError',

    getUserIdStart: '[register]getUserIdStart',
    getUserIdSuccess: '[register]getUserIdSuccess',
    getUserIdError: '[register]getUserIdError',

    getFilteredStart: '[register]getFilteredStart',
    getFilteredSuccess: '[register]getFilteredSuccess',
    getFilteredError: '[register]getFilteredError',


}
export const getRegisterActionType = {
    getAdminRegisterAction: '[register]getAdminRegisterAction',
    getUserIdAction: '[register]getUserIdAction',
    filteredByStatusAction: '[register]filteredByStatusAction',
}


const mutations = {
    [getRegisterMutationsType.getRegisterStart] () {},
    [getRegisterMutationsType.getRegisterSuccess] (state, payload) {
        state.items = payload
    },
    [getRegisterMutationsType.getRegisterError] (state, payload) {
        state.errors = payload
    },

    [getRegisterMutationsType.getUserIdStart]() {},
    [getRegisterMutationsType.getUserIdSuccess](state, payload) {
        state.userId = payload
    },
    [getRegisterMutationsType.getUserIdStart](state, payload) {
        state.errors = payload
    },

    [getRegisterMutationsType.getFilteredStart]() {},
    [getRegisterMutationsType.getFilteredSuccess](state, payload) {
        state.userId = payload
    },
    [getRegisterMutationsType.getFilteredError](state, payload) {
        state.errors = payload
    },

}

const actions = {
    [getRegisterActionType.getAdminRegisterAction]({commit},{skip, status}) {
        return new Promise((resolve => {
            commit(getRegisterMutationsType.getRegisterStart)
            axios.get(`admin/registration?reg_status=${status}&order_dir=DESC&order_by=created_on&take=10&skip=${skip}`)
                .then(response => {
                    commit(getRegisterMutationsType.getRegisterSuccess, response)
                    resolve(response)
                })
                .catch(err => {
                    commit(getRegisterMutationsType.getRegisterError, err.response)
                })
        }))
    },
    [getRegisterActionType.getUserIdAction]({commit}) {
        return new Promise((resolve => {
            commit(getRegisterMutationsType.getUserIdStart)
            axios.get('user/me')
                .then(response => {
                    commit(getRegisterMutationsType.getRegisterSuccess, response.data)
                    resolve(response.data)
                })
                .catch(err => {
                    commit(getRegisterMutationsType.getRegisterError, err.response)
                })
        }))
    },

    [getRegisterActionType.filteredByStatusAction]({commit}, {status}) {
        return new Promise((resolve => {
            commit(getRegisterMutationsType.getFilteredStart)
            axios.get(`/admin/registration?reg_status=${status}&order_dir=DESC&order_by=created_on&take=10&skip=0`)
                .then(response => {
                    commit(getRegisterMutationsType.getFilteredSuccess, response)
                    resolve(response)
                })
                .catch(err => {
                    commit(getRegisterMutationsType.getFilteredError, err.response)
                })
        }))
    }
}

export default {
    state,
    mutations,
    actions
}