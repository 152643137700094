<template>
  <div
    :class="{
      [$style.footerDark]: true,
      [$style.footerFullWidth]: !settings.isContentMaxWidth,
    }"
  >
    <div class="pt-5 pb-4" :class="$style.outer">
      <div :class="$style.container">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <h5 class="font-weight-bold mb-4">Quick Links</h5>
            <div class="row">
              <div class="col-sm-6">
                <div class="d-flex flex-column">
                  <a class="mb-1 vb__utils__link" href="#"> Documentation </a>
                  <a class="mb-1 vb__utils__link" href="#"> Service Rules </a>
                  <a class="mb-1 vb__utils__link" href="#"> User Settings </a>
                  <a class="mb-1 vb__utils__link" href="#">
                    Dashboard Setings
                  </a>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="d-flex flex-column mb-4">
                  <a class="mb-1 vb__utils__link" href="#"> User Settings </a>
                  <a class="mb-1 vb__utils__link" href="#">
                    Dashboard Settings
                  </a>
                  <a class="mb-1 vb__utils__link" href="#"> Documentation </a>
                  <a class="mb-1 vb__utils__link" href="#"> Service Rules </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-4">
            <h5 class="font-weight-bold mb-4">About</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industrys standard dummy text ever since the 1500s, when an unknown
              printer took a galley of type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div class="col-md-6 col-lg-4">
            <h5 class="font-weight-bold mb-4">Get In Touch</h5>
            <input
              class="form-control mb-4"
              :class="$style.input"
              type="email"
              placeholder="Enter your email"
            />
            <button type="button" class="btn btn-primary">Subscribe</button>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.bottom" class="py-4">
      <div :class="$style.container">
        <div class="d-sm-flex align-items-sm-center">
          <div class="clearfix mr-sm-auto" :class="$style.logo">
             
            <div><img src="/img/logo.png" alt="logo"></div>
            <!-- <div :class="$style.logo__name"></div> -->
            <!-- <div :class="$style.logo__descr">{{ settings.version }}</div> -->
          </div>
          <div class="d-flex flex-column flex-sm-row">
            <a class="mb-1 mb-sm-0 px-sm-3" href="#"> About </a>
            <a class="mb-1 mb-sm-0 px-sm-3" href="#"> Terms of Use </a>
            <a class="mb-1 mb-sm-0 px-sm-3" href="#"> Buy Now </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
