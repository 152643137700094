import axios from 'axios';
import {notification} from "ant-design-vue";
import apiClient from "./services/axios";

let instance = axios;
instance.defaults.baseURL = process.env.VUE_APP_PEERING_HUB_API;
const token = localStorage.getItem('token');
if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

axios.interceptors.response.use((response) => {
    return response
}, async function (error) {

    if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
        localStorage.clear()
        location.reload();
        return;
    }
    return Promise.reject(error);
});

export default instance;

