export const getAdminMenuData = [
    {
        title: 'Dashboard',
        key: '__profile',
        url: '/dashboard',
    },
    {
        title: 'Registration',
        key: '__registration',
        url: '/registration',
    },
    {
        title: 'LOA',
        key: '__loa',
        url: '/loa',
    },
    {
        title: 'Company',
        key: '__company',
        url: '/company',
    },
    {
        title: 'My Invoice',
        key: '__invoice',
        url: '/admin-invoice',
    },
    {
        title: 'My Payment',
        key: '__payment',
        url: '/admin-payment',
    },
]