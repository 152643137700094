<template>
  <a-menu-item
    :key="menuInfo.key"
    v-bind="$props"
    :disabled="menuInfo.disabled"
  >
    <router-link
      v-if="menuInfo.url"
      :to="menuInfo.url"
    >
      <span
        :class="styles.title"
        class="ml-5"
      >{{ menuInfo.title }}</span>
      <span
        v-if="menuInfo.count"
        class="badge badge-success ml-2"
      >{{ menuInfo.count }}</span>
      <i
        v-if="menuInfo.icon"
        :class="[styles.icon, menuInfo.icon]"
        class="menuIcon"
      />
    </router-link>
    <span
      v-if="!menuInfo.url"
      :to="menuInfo.url"
    >
      <span
        :class="styles.title"
        class="ml-5"
      >{{ menuInfo.title }}</span>
      <span
        v-if="menuInfo.count"
        class="badge badge-success ml-2"
      >{{ menuInfo.count }}</span>
      <i
        v-if="menuInfo.icon"
        :class="[styles.icon, menuInfo.icon]"
        class="menuIcon"
      />
    </span>
  </a-menu-item>
</template>

<script>
import { Menu } from 'ant-design-vue'

export default {
  name: 'SubMenu',
  props: {
    ...Menu.Item.props,
    menuInfo: {
      type: Object,
      default: () => {},
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped>
.menuIcon{
  left: 14px !important;
}
</style>
