<template>
  <a-sub-menu
    :key="menuInfo.key"
    v-bind="$props"
  >
    <template #title>
      <span>
        <span
          :class="styles.title"
          class="ml-5"
        >{{ menuInfo.title }}</span>
        <span
          v-if="menuInfo.count"
          class="badge badge-success ml-2"
        >{{ menuInfo.count }}</span>
        <i
          v-if="menuInfo.icon"
          :class="[styles.icon, menuInfo.icon]"
          class="menuIcon"
        />
      </span>
    </template>
    <template v-for="item in menuInfo.children">
      <item
        v-if="!item.children && !item.divider"
        :key="item.key"
        :menu-info="item"
        :styles="styles"
      />
      <sub-menu
        v-if="item.children"
        :key="item.key"
        :menu-info="item"
        :styles="styles"
      />
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from 'ant-design-vue'
import Item from './item'

export default {
  name: 'SubMenu',
  components: { Item },
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => {},
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped>
.menuIcon{
  left: 14px !important;
}
</style>
