import axios from '@/axios'


const state = {
   items: null,
   errors: null
}

export const getLoaMutationsType = {
    getLoaStart: '[loa]getLoaStart',
    getLoaSuccess: '[loa]getLoaSuccess',
    getLoaError: '[loa]getLoaError',

    getFilteredStart: '[loa]getFilteredStart',
    getFilteredSuccess: '[loa]getFilteredSuccess',
    getFilteredError: '[loa]getFilteredError',
}
export const getLoaActionType = {
    getLoaAction: '[loa]getLoaAction',
    filteredByStatusAction: '[loa]filteredByStatusAction',
}


const mutations = {
    [getLoaMutationsType.getLoaStart] () {},
    [getLoaMutationsType.getLoaSuccess] (state, payload) {
        state.items = payload
    },
    [getLoaMutationsType.getLoaError] (state, payload) {
        state.errors = payload
    },

    [getLoaMutationsType.getFilteredStart] () {},
    [getLoaMutationsType.getFilteredSuccess] (state, payload) {
        state.items = payload
    },
    [getLoaMutationsType.getFilteredError] (state, payload) {
        state.errors = payload
    },
}

const actions = {
    [getLoaActionType.getLoaAction]({commit}, {skip, status}) {
        return new Promise((resolve => {
            commit(getLoaMutationsType.getLoaStart)
            axios.get(`/admin/signing/loa?status=${status}&order_dir=DESC&order_by=created_on&take=10&skip=${skip}`)
                .then(response => {
                    commit(getLoaMutationsType.getLoaSuccess, response)
                    resolve(response)
                })
                .catch(err => {
                    commit(getLoaMutationsType.getLoaError, err.response)
                })
        }))
    },
    [getLoaActionType.filteredByStatusAction]({commit}, {status}) {
        return new Promise((resolve => {
            commit(getLoaMutationsType.getFilteredStart)
            axios.get(`/admin/signing/loa?status=${status}&order_dir=DESC&order_by=created_on&take=10&skip=0`)
                .then(response => {
                    commit(getLoaMutationsType.getFilteredSuccess, response)
                    resolve(response)
                })
                .catch(err => {
                    commit(getLoaMutationsType.getFilteredError, err.response)
                })
        }))
    }
}

export default {
    state,
    mutations,
    actions
}