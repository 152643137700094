import router from '@/router'
import { notification } from 'ant-design-vue'
import axios from '@/axios';
import * as firebase from '@/services/firebase'
import * as jwt from '@/services/jwt'
import moment from 'moment'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

const DEV = process.env.VUE_APP_AUTHENTICATED
  ? {
    id: '1',
    name: 'Tom Jones',
    role: 'admin',
    email: 'demo@sellpixels.com',
    avatar: '',
    authorized: true,
    accountFetchIsTouched: true,
  }
  : {}

export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    admin: false,
    email: '',
    avatar: '',
    authorized: false,
    loading: false,
    accountFetchIsTouched: false,
    ...DEV, // remove it, used for demo build
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { email, password } = payload
      commit('SET_STATE', {
        loading: true,
      })

      axios.post(('/auth/login'), {
        email, password,
      }).then(success => {
        if (success) {
          notification.success({
            message: 'Logged In',
            description: 'You have successfully logged in!',
          })
          commit('SET_STATE', {
            id: '',
            name: '',
            role: '',
            email: '',
            avatar: '',
            admin: success.data.admin,
            authorized: true,
            loading: false,
          })
          localStorage.setItem('token', success.data.access_token);
          localStorage.setItem('is_admin', success.data.is_admin);
          localStorage.setItem('expires_at', new Date(success.data.expires_at).getTime());
          axios.defaults.headers.common['Authorization'] = `Bearer ${success.data.access_token}`
          router.push('/dashboard');
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      }).catch(() => {
        notification.error({
          message: 'Try again',
          description: 'The login or password was entered incorrectly.',
        })
        commit('SET_STATE', {
          loading: false,
        })
      })
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      const { email, password, name } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const register = mapAuthProviders[rootState.settings.authProvider].register
      register(email, password, name).then(success => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT')
          notification.success({
            message: 'Succesful Registered',
            description: 'You have successfully registered!',
          })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    LOAD_CURRENT_ACCOUNT({ commit, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })

      const currentAccount = mapAuthProviders[rootState.settings.authProvider].currentAccount
      currentAccount().then(response => {
        if (response) {
          const { id, email, name, avatar, role } = response
          commit('SET_STATE', {
            id,
            name,
            email,
            avatar,
            role,
            authorized: true,
          })
        }
        commit('SET_STATE', {
          loading: false,
        })
      })
    },
    LOGOUT({ commit, rootState }) {
      const logout = mapAuthProviders[rootState.settings.authProvider].logout
      logout().then(() => {
        commit('SET_STATE', {
          id: '',
          name: '',
          role: '',
          email: '',
          avatar: '',
          authorized: false,
          loading: false,
        })
        router.push('/auth/login')
        localStorage.clear();
      })
    },
  },
  getters: {
    user: state => state,
  },
}
