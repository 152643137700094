<template>
  <div :class="$style.breadcrumbs">
    <div :class="$style.path">
      <div
        v-if="!breadcrumb.length || breadcrumb.length === 0"
        style="cursor: pointer;"
        @click="redirectUrlInBredcrubs"
      >
        <a
          href="javascript:void(0)"
          @click="$router.push({name:'home'})"
        >Home</a>

        . {{ InActiveType }}
        <span style="font-weight: 700; color:black; cursor: pointer!important;">{{ tnType }}
        </span>
      </div>
      <router-link
        v-else
        to="/"
      >
        Home
      </router-link>
      <template v-for="(item, index) in breadcrumb">
        <span
          v-if="index != 0"
          :key="index"
        >
          <span :class="$style.arrow" />
          <span class="breadcrumbs-routing">{{ item.title }}</span>
        </span>
      </template>
      <span v-if="activeItem">
        <span :class="$style.arrow" />
        <strong :class="$style.current">{{ activeItem.title }}</strong>
      </span>
    </div>
  </div>
</template>

<script>
import {ref, watch, onMounted, computed} from 'vue'
import {useRoute} from 'vue-router'
import {getMenuData} from '@/services/menu'
import reduce from 'lodash/reduce'

export default {
  name: 'Breadcrumbs',
  setup() {
    const route = useRoute()
    const breadcrumb = ref([])
    const activeItem = ref([])
    const menuData = getMenuData
    const routePath = computed(() => route.path)

    const getPath = (data, url, parents = []) => {
      if (url === '/') {
        url = '/dashboard'
      }
      const items = reduce(
          data,
          (result, entry) => {
            if (result.length) {
              return result
            }
            if (entry.children) {
              const nested = getPath(entry.children, url, [entry].concat(parents))
              return (result || []).concat(nested.filter(e => !!e))
            }
            if (entry.url === url) {
              return [entry].concat(parents)
            }
            return result
          },
          [],
      )
      activeItem.value = items[0]
      return items
    }

    onMounted(() => {
      breadcrumb.value = getPath(menuData, routePath.value)
    })

    watch(routePath, routePath => (breadcrumb.value = getPath(menuData, routePath)))

    return {
      breadcrumb,
      activeItem,
    }
  },
  data: () => ({
    pathName: ''
  }),
  computed: {
    tnType() {
      if (this.$route.name === 'home'){
        return 'Home'
      }else if (this.$route.name === 'signing-import-tn') {
        return `> My TNs > Import TN`
      } else if (this.$route.name === 'sign-loa-add') {
        return '. My LOAs . Add LOA'
      } else if (this.$route.name === 'upload-certificate') {
        return '. Certificate . Upload Certificate'
      } else if (this.$route.name === 'add-tn') {
        return '> My TNs > Add TN'
      } else if (this.$route.name === 'import-tn') {
        return '> My TNs > Import TN'
      } else if (this.$route.name === 'view-certificate') {
        return '> Listing > View'
      } else if(this.$route.name === 'profile-info') {
        return '. Edit'
      } else return null
    },
    InActiveType() {
      return this.$route.path.split('/')[1].charAt(0).toUpperCase() + this.$route.path.split('/')[1].slice(1)
    }
  },
  methods: {
    redirectUrlInBredcrubs() {
      if (this.$route.name === 'import-tn' && this.InActiveType === 'Authentication') {
        this.$router.push({name: 'auth-tn'})
      }else if(this.$route.name === 'upload-certificate') {
        this.$router.push({name: 'auth-certificate'})
      }else if(this.$route.name === 'add-tn' && this.InActiveType === 'Authentication'){
        this.$router.push({name: 'auth-tn'})
      } else if(this.$route.name === 'signing-add-tn' && this.InActiveType === 'Signing') {
        this.$router.push({name: 'sign-tn'})
      }else if(this.$route.name === 'signing-import-tn' && this.InActiveType === 'Signing') {
        this.$router.push({name: 'sign-tn'})
      }else if(this.$route.name === 'sign-loa-add') {
        this.$router.push({name: 'sign-loa'})
      }
    },
  }
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
