export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  // {
  //   title: 'Applications',
  //   key: '__applications',
  //   url: '/applications',
  // },
  // {
  //   title: 'Users',
  //   key: '__users',
  //   url: '/users',
  // },
  {
    title: 'My Invoice',
    icon: 'fe fe-file-text',
    key: '__invoice',
    url: '/invoice',
  },
  {
    title: 'My Payment',
    icon: "fe fe-credit-card",
    key: '__payment',
    url: '/payment',
  },
  {
    title: 'Dashboard',
    icon: "fe fe-home",
    key: '__profile',
    url: '/dashboard',
  },
  {
    title: 'Certificate',
    icon: "fe fe-star",
    key: '__certificate',
    url: '/certificate',
    children: [
      {
        title: 'Listing',
        key: '__listing',
        url: '/listing',
      },
      {
        title: 'Generate',
        key: '__generate',
        url: '/certificate/generate',
      },
    ],
  },
  {
    title: 'Authentication',
    key: '__authentication',
    url: '/authentication',
    icon: "fe fe-layers",
    children: [
      {
        title: 'Certificate',
        key: '__certificate',
        url: '/authentication/certificate',
        icon: 'fe fe-certificate',
      },
      {
        title: 'Whitelist SBCs',
        key: '__auth-whitelist',
        url: '/authentication/whitelist',
      },
      {
        title: 'Policies',
        key: '__policies',
        url: '/authentication/policies',
      },
      {
        title: 'Report',
        key: '__auth-report',
        url: '/authentication/report',
      },
      {
        title: 'My TNs',
        key: '__tn',
        url: '/authentication/tn',
      },
    ],
  },
  {
    title: 'Verification',
    key: '__verification',
    url: '/verification',
    icon: 'fe fe-bookmark',
    children: [
      {
        title: 'Whitelist SBCs',
        key: '__verif-whitelist',
        url: '/verification/whitelist',
      },
      {
        title: 'Report',
        key: '__verification-report',
        url: '/verification/report',
      },
    ],
  },
  {
    title: 'Signing',
    key: '__signing',
    url: '/signing',
    icon: 'fe fe-user',
    children: [
      {
        title: 'Whitelist SBCs',
        key: '__sing-whitelist',
        url: '/signing/whitelist',
      },
      {
        title: 'My TNs',
        key: '__signing-tn',
        url: '/signing/tn',
      },
      {
        title: 'My LOAs',
        key: '__loa',
        url: '/signing/loa',
      },
      {
        title: 'Report',
        key: '__signing-report',
        url: '/signing/report',
      },
    ],
  },
  // VB:REPLACE-END:MENU-CONFIG
];
