import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  history: createWebHistory(),
  routes: [
    {
      path: '/auth/register',
      meta: {
        title: 'Sign Up',
      },
      component: () => import('./pages/register'),
    },
    {
      // path:'/',
      path: '/dashboard',
      name: 'home',
      redirect: '/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // VB:REPLACE-START:ROUTER-CONFIG
        // {
        //   path: '/users',
        //   meta: { title: 'Applications' },
        //   component: () => import('./pages/users'),
        // },
        // {
        //   path: '/application',
        //   name: 'application',
        //   meta: { title: 'Application' },
        //   component: () => import('./pages/application'),
        // },
        // {
        //   path: '/dashboard',
        //   name: 'profile',
        //   meta: { title: 'Dashboard' },
        //   component: () => import('./pages/profile/index.vue'),
        // },
        {
          path: '/loa',
          name: 'admin-signing-loa',
          meta: { title: 'AdminLoa' },
          component: () => import('./pages/adminSide/Loa.vue'),
        },
        {
          path: '/registration',
          name: 'registration',
          meta: { title: 'Registration' },
          component: () => import('./pages/adminSide/Registration.vue'),
        },
        {
          path: '/registration/view-detail/:id',
          name: 'view-detail',
          meta: { title: 'View-Detail' },
          component: () => import('./pages/adminSide/RegistrationDetaile.vue'),
        },
        {
          path: '/company',
          name: 'company',
          meta: { title: 'Company' },
          component: () => import('./pages/adminSide/Company.vue'),
        },
        {
          path: '/admin-invoice',
          name: 'admin-invoice',
          meta: { title: 'AdminInvoice' },
          component: () => import('./pages/adminSide/Invoice.vue'),
        },
        {
          path: '/invoice',
          name: 'invoice',
          meta: { title: 'Invoice' },
          component: () => import('./pages/my-invoice.vue'),
        },
        {
          path: '/payment',
          name: 'payment',
          meta: { title: 'My Payment' },
          component: () => import('./pages/my-payment.vue'),
        },
        {
          path: '/admin-payment',
          name: 'admin-payment',
          meta: { title: 'My Payment +++r' },
          component: () => import('./pages/adminSide/Payment.vue'),
        },
        {
          path: '/authentication/report',
          name: 'auth-report',
          meta: { title: 'Reports' },
          component: () => import('./pages/authentication/report.vue'),
        },
        {
          path: '/authentication/whitelist',
          name: 'authentication-whitelist',
          meta: { title: 'WhiteList' },
          component: () => import('./pages/authentication/whitelist.vue'),
        },
        {
          path: '/authentication/whitelist/create',
          name: 'authentication-whitelist-create',
          meta: { title: 'Whitelist Create' },
          component: () => import('./pages/authentication/whitelist-form.vue'),
        },
        {
          path: '/authentication/whitelist/update',
          name: 'authentication-whitelist-update',
          meta: { title: 'Whitelist Update' },
          component: () => import('./pages/authentication/whitelist-form.vue'),
        },
        {
          path: '/verification/report',
          name: 'verif-report',
          meta: { title: 'Reports' },
          component: () => import('./pages/verification/report.vue'),
        },
        {
          path: '/signing/report',
          name: 'sign-report',
          meta: { title: 'Reports' },
          component: () => import('./pages/signing/report.vue'),
        },
        {
          path: '/signing/tn',
          name: 'sign-tn',
          meta: { title: 'Reports' },
          component: () => import('./pages/signing/tn.vue'),
        },
        {
          path: '/signing/whitelist',
          name: 'sign-whitelist',
          meta: { title: 'Reports' },
          component: () => import('./pages/signing/whitelist.vue'),
        },
        {
          path: '/signing/tn/add',
          name: 'signing-add-tn',
          meta: { title: 'Add TN' },
          component: () => import('./pages/signing/tn/create.vue'),
        },
        {
          path: '/signing/tn/import',
          name: 'signing-import-tn',
          meta: { title: 'Import TN' },
          component: () => import('./pages/signing/tn/import.vue'),
        },
        {
          path: '/signing/loa',
          name: 'sign-loa',
          meta: { title: 'Reports' },
          component: () => import('./pages/signing/loa.vue'),
        },
        {
          path: '/signing/loa/add',
          name: 'sign-loa-add',
          meta: { title: 'Reports' },
          component: () => import('./pages/signing/add-loa.vue'),
        },
        {
          path: '/listing',
          name: 'listing',
          meta: { title: 'Certificate List' },
          component: () => import('./pages/certificate/listing.vue'),
        },
        {
          path: '/certificate/view',
          name: 'view-certificate',
          meta: { title: 'Certificate' },
          component: () => import('./pages/certificate/view.vue'),
        },
        {
          path: '/certificate/generate',
          name: 'generate',
          meta: { title: 'Add Certificate' },
          component: () => import('./pages/certificate/create.vue'),
        },
        {
          path: '/profile/profile-info',
          name: 'profile-info',
          meta: { title: 'My Profile' },
          component: () => import('./pages/profile/profile-info'),
        },
        {
          path: '/profile/my-ocn',
          name: 'my-ocn',
          component:() => import('./pages/profile/my-ocn'),
        },
        {
          path:'/profile/add-my-ocn',
          name:'add-my-ocn',
          component:() => import('./pages/profile/add-my-ocn'),
        },
        {
          path: '/profile/numbers-info',
          name: 'numbers-info',
          meta: { title: 'Profile' },
          component: () => import('./pages/profile/index.vue'),
        },
        {
          // path: '/profile/services-info',
          path: '/dashboard',
          name: 'services-info',
          meta: { title: 'My Services' },
          component: () => import('./pages/profile/services-info.vue'),
        },
        {
          path: '/create/certificate',
          name: 'create-certificate',
          meta: { title: 'My Services' },
          component: () => import('./pages/create/certificate.vue'),
        },
        {
          path: '/services/pay',
          name: 'pay',
          meta: { title: 'Services Pay' },
          component: () => import('./pages/profile/pay.vue'),
        },
        {
          path: '/signing/tn/buy',
          name: 'tn-buy',
          meta: { title: 'signing Buy' },
          component: () => import('./pages/signing/buy.vue'),
        },
        {
          path: '/authentication/certificate',
          name: 'auth-certificate',
          meta: { title: 'My sertificate' },
          component: () => import('./pages/authentication/certificate.vue'),
        },
        {
          path: '/authentication/policies',
          name: 'policies',
          meta: { title: 'Policies' },
          component: () => import('./pages/authentication/policies.vue'),
        },
        {
          path: '/authentication/policies/create',
          name: 'policies-create',
          meta: { title: 'Policies Create' },
          component: () => import('./pages/authentication/policies-form.vue'),
        },
        {
          path: '/authentication/tn',
          name: 'auth-tn',
          meta: { title: 'TN' },
          component: () => import('./pages/authentication/tn.vue'),
        },
        {
          path: '/authentication/tn/add',
          name: 'add-tn',
          meta: { title: 'Add TN' },
          component: () => import('./pages/authentication/tn/create.vue'),
        },
        {
          path: '/authentication/tn/import',
          name: 'import-tn',
          meta: { title: 'Import TN' },
          component: () => import('./pages/authentication/tn/import.vue'),
        },
        {
          path: '/authentication/certificate/upload',
          name: 'upload-certificate',
          meta: { title: 'My sertificate' },
          component: () => import('./pages/authentication/upload-certificate.vue'),
        },
        {
          path: '/verification/whitelist',
          name: 'verif-whitelist',
          meta: { title: 'WhiteList' },
          component: () => import('./pages/verification/whitelist.vue'),
        },
        {
          path: '/verification/whitelist/create',
          name: 'verif-whitelist-create',
          meta: { title: 'Whitelist Create' },
          component: () => import('./pages/verification/whitelist-create.vue'),
        },



        // VB:REPLACE-END:ROUTER-CONFIG
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/change-password',
          meta: {
            title: 'Change password',
          },
          component: () => import('./pages/change-password'),
        },
        {
          path: '/confirm',
          meta: {
            title: 'Confirm',
          },
          component: () => import('./pages/confirm'),
        },
        {
          path: '/auth/404',
          name: 'route404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          name: 'login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 300)
  const value = !window.location.pathname.includes('confirm') && !window.location.pathname.includes('password')
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!localStorage.getItem('token') && value) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
