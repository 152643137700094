<template>
  <styleLoader />
  <localization />
  <vb-support-chat />
</template>

<script>
import VbSupportChat from '@/@vb/components/SupportChat'
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import qs from 'qs'
import Localization from '@/localization'
import StyleLoader from '@/styleLoader'
import moment from 'moment'
export default {
  name: 'App',
  components: { Localization, StyleLoader, VbSupportChat },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const logo = computed(() => store.getters.settings.logo)
    const routeTitle = computed(() => route.meta.title)
    const currentRoute = computed(() => route)
    const authorized = computed(() => store.getters['user/user'].authorized)

    // watch page title change
    watch(
      [logo, routeTitle],
      ([logo, routeTitle]) => (document.title = `${logo} | ${routeTitle}` || `${logo}`),
    )

    // initial auth check
    onMounted(() => {
      store.dispatch('user/LOAD_CURRENT_ACCOUNT')
    })
  },
  data() {
    return {
        interval: null,
        expires_at: localStorage.getItem('expires_at')
    }
  },
  created() {
    var that = this;

    this.interval = setInterval(function(){
      that.eventInterval()
    }, 72000000);

  },
  methods: {
    eventInterval() {
      if(localStorage.getItem('token') && (new Date().getTime() - this.expires_at) >= 0){
        localStorage.clear();
        setTimeout(() => {
          location.reload();
        },100)
      }else {
        clearInterval(this.interval);
        this.interval = null;
      }
    }
  },
}
</script>
