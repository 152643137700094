import { createStore } from 'vuex'
import user from './user'
import settings from './settings'
import adminLoa from './adminLoa'
import adminRegister from "./adminRegister/adminRegister";
import { notification } from 'ant-design-vue'

export default createStore({
  modules: {
    user,
    settings,
    adminLoa,
    adminRegister
  },
  state: {},
  mutations: {},
  actions: {
    error({ state }, err) {
      const error = err.response.data.message;
      const errorNumber = err.response.data.number
      const description = Array.isArray(error) ? [...error.message].join(', ') : error.message;
      console.log(description, Array.isArray(error), error)
      notification.error({
        message: `${error} ${errorNumber}`,
      })
    },
    created({ state }, msg) {
      notification.success({
        message: `Сreated ${msg}`,
        // description: 'Everything was successfully created, wait for a redirect',
        //  description: 'You have successfully logged in!',
      });
    },
    deleted({ state }, msg) {
      notification.success({
        message: `Deleted ${msg}`,
        // description: 'Everything was successfully created, wait for a redirect',
        //  description: 'You have successfully logged in!',
      });
    },
  },
  getters: {},
})
